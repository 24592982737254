// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-247-unblocking-js": () => import("./../src/pages/247-unblocking.js" /* webpackChunkName: "component---src-pages-247-unblocking-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bin-cleaning-js": () => import("./../src/pages/bin-cleaning.js" /* webpackChunkName: "component---src-pages-bin-cleaning-js" */),
  "component---src-pages-carpark-clean-js": () => import("./../src/pages/carpark-clean.js" /* webpackChunkName: "component---src-pages-carpark-clean-js" */),
  "component---src-pages-chute-cleaning-js": () => import("./../src/pages/chute-cleaning.js" /* webpackChunkName: "component---src-pages-chute-cleaning-js" */),
  "component---src-pages-home-clean-js": () => import("./../src/pages/home-clean.js" /* webpackChunkName: "component---src-pages-home-clean-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-strata-cleaning-js": () => import("./../src/pages/strata-cleaning.js" /* webpackChunkName: "component---src-pages-strata-cleaning-js" */),
  "component---src-pages-thank-you-js": () => import("./../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-tool-js": () => import("./../src/pages/tool.js" /* webpackChunkName: "component---src-pages-tool-js" */)
}

